<template>
  <div id="publication-list">
    <v-dialog
      v-model="isFormActive"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 500 : '100%'"
    >
      <CollectionForm
        v-model="item"
        :is-form-active="isFormActive"
        :pay-options="payOptions"
        :plan-options="planOptions"
        @refetch-data="fetchItems"
      >
        <template v-slot:form-title>
          <span class="font-weight-semibold text-base text--primary">{{ $t('Collecte des éléments') }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isFormActive = !isFormActive"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:form-cancel>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="isFormActive = !isFormActive"
          >
            {{ $t('Annuler') }}
          </v-btn>
        </template>
      </CollectionForm>
    </v-dialog>
    <v-dialog
      v-model="isFormAttributionActive"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 500 : '100%'"
    >
      <AttributionForm
        v-model="item"
        :is-form-active="isFormAttributionActive"
        :pay-options="payOptions"
        :plan-options="planOptions"
        @refetch-data="fetchItems"
      >
        <template v-slot:form-title>
          <span class="font-weight-semibold text-base text--primary">{{ $t('Attribuer le dossier') }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isFormAttributionActive = !isFormAttributionActive"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:form-cancel>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="isFormAttributionActive = !isFormAttributionActive"
          >
            {{ $t('Annuler') }}
          </v-btn>
        </template>
      </AttributionForm>
    </v-dialog>
    <!-- list filters -->
    <v-card>
      <v-card-title>
        {{ $t("Collecte des éléments") }}<v-chip outlined class="ml-2">{{ totalItems }}</v-chip>  <v-spacer /> <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-circular>
      </v-card-title>
      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        class="fixed-table"
        :headers="filters"
        :items="items"
        :options.sync="options"
        :page.sync="page"
        :server-items-length="totalItems"
        :loading="loading"
        :loading-text="$t('Chargement des éléments')"
        :no-data-text="$t('Pas de données')"
        :no-results-text="$t('Aucun résultat')"
        :mobile-breakpoint="250"
        hide-default-footer
        :show-select="false"
        dense fixed-header :height="tableHeight"
        @page-count="pageCount = $event"
      >
        <template #body.prepend="{ items, headers }">
          <header-filters :headers="filters" />
        </template>
        <template #item="options">
          <Row :options="options"
               :config="{ url: '/publications/action/', author: 'author', manuscript: 'manuscript', resource: 'Collect' }"
               @refetch-data="fetchItems"
          >
        <!-- name -->
        <template #[`item.author.full_name`]="{item,index}">
          <author-widget :object="item.author" />
<!--          <div class="d-flex align-center" v-if="item.author && item.author.id">
            <v-avatar
              :color="item.author.avatar ? '' : 'primary'"
              :class="item.author.avatar ? '' : 'v-avatar-light-bg primary&#45;&#45;text'"
              size="30"
            >
              <v-img
                v-if="item.author.avatar"
                :lazy-src="require(`@/assets/images/avatars/2.png`)"
                :src="item.author.avatar || require(`@/assets/images/avatars/2.png`)"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(item.author.full_name) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'author-view', params : { id : item.author_id } }"
                class="text&#45;&#45;primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.author.full_name }}
              </router-link>
              &lt;!&ndash;              <small>@{{ item.publicationname }}</small>&ndash;&gt;
            </div>
          </div>-->
        </template>
        <template #[`item.author.old_author`]="{item}">
          <v-icon
            v-if="item.author && item.author.old_author"
            small
            color="success"
          >
            {{ icons.mdiCheckCircle }}
          </v-icon>
        </template>
        <template #[`item.author.published`]="{item}">
          <div class="text-center">
            <v-icon
              v-if="item.published"
              small
              color="success"
            >
              {{ icons.mdiCheckCircle }}
            </v-icon>
          </div>
        </template>
        <!-- pay -->
        <template #[`item.country`]="{item}">
          <div
            v-if="item.country"
            class="d-flex align-center"
          >
            <span class="text-capitalize">{{ item.country.name }}</span>
          </div>
        </template>
        <template #[`item.completion_date`]="{item}">
          <span v-if="item.completion_date && !item.completion_date.includes('0000')">{{ formatDate(item.completion_date || null, { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span>
        </template>
        <template #[`item.is_reading`]="{item}">
          <span>
                <v-icon  v-if="item.is_reading" small color="success">
                {{ icons.mdiCheckCircle }}
              </v-icon>
              </span>
        </template>
        <!-- status -->
        <template #[`item.request_elements1`]="{item}">
          <div :style="stateColor(item.publication_colors, 'request_elements')">
          <v-tooltip bottom >
              <template v-slot:activator="{ on, attrs }">
                <span
                  style="cursor: pointer"
                  v-bind="attrs"
                  @click="openDialog({...item, action: 'collecte_elements',activity_date: item.request_elements,date_column:'request_elements', 'activity_date_label': $t('Demande des éléments'), info: $t('Confirmez-vous que les éléments ont été demandés ?') })"
                  v-on="on"
                ><span v-if="item.request_elements && !item.request_elements.includes('0000')">{{ formatDate(item.request_elements || null, { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span></span>
              </template>
              <span v-if="!item.publication_records  || (item.publication_records  && item.publication_records.filter(el=>el.type === 'collecte_elements' && el.comment).length === 0)">{{ $t('Cliquez ici pour mettre à jour') }}</span>
              <template v-else>
                <div>
                  <p
                    v-for="precord in item.publication_records.filter(el=>el.type === 'collecte_elements' && el.comment)"
                    :key="precord.id"
                  >
                    <span class="mr-2">{{ precord.user?precord.user.code:'' }}</span>
                    <span class="mr-2" v-if="precord.date_record">{{ formatDate(precord.date_record || null, { month: 'numeric', day: 'numeric' }) }}</span>
                    <span class="mr-2">{{ precord.comment }}</span>
                  </p>
                </div>
              </template>
            </v-tooltip>
          <v-tooltip
            v-if="!item.request_elements || item.request_elements.includes('0000')"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn

                x-small
                color="primary"
                text
                v-bind="attrs"
                v-on="on"
                @click="openDialog({...item, action: 'collecte_elements',activity_date: item.request_elements,date_column:'request_elements', 'activity_date_label': $t('Demande des éléments'), info: $t('Confirmez-vous que les éléments ont été demandés ?') })"
              >
                <v-icon color="primary">
                  {{ icons.mdiCheckboxMarkedCircleOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('Cliquez ici pour mettre à jour') }}</span>

          </v-tooltip>
          </div>
        </template>
        <template #[`item.author_avatar1`]="{item}">
          <div :style="stateColor(item.publication_colors, 'author_avatar')">
            <v-tooltip bottom >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    style="cursor: pointer"
                    v-bind="attrs"
                    @click="openDialog({...item, action: 'collecte_author_avatar',activity_date: item.author_avatar,date_column:'author_avatar', 'activity_date_label': $t('Collecte photo auteur'), info: $t(' Confirmez-vous que les photos auteurs ont été collectées ?') })"
                    v-on="on"
                  ><span v-if="item.author_avatar && !item.author_avatar.includes('0000')">{{ formatDate(item.author_avatar || null, { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span></span>
                </template>
                <span v-if="!item.publication_records  || (item.publication_records  && item.publication_records.filter(el=>el.type === 'collecte_author_avatar' && el.comment).length === 0)">{{ $t('Cliquez ici pour mettre à jour') }}</span>
                <template v-else>
                  <div>
                    <p
                      v-for="precord in item.publication_records.filter(el=>el.type === 'collecte_author_avatar' && el.comment)"

                      :key="precord.id"
                    >
                      <span class="mr-2">{{ precord.user?precord.user.code:'' }}</span>
                      <span class="mr-2" v-if="precord.date_record">{{ formatDate(precord.date_record || null, { month: 'numeric', day: 'numeric' }) }}</span>
                      <span class="mr-2">{{ precord.comment }}</span>
                    </p>
                  </div>
                </template>
              </v-tooltip>
            <v-tooltip
              v-if="!item.author_avatar || item.author_avatar.includes('0000')"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn

                  x-small
                  color="primary"
                  text
                  v-bind="attrs"
                  v-on="on"
                  @click="openDialog({...item, action: 'collecte_author_avatar',activity_date: item.author_avatar,date_column:'author_avatar', 'activity_date_label': $t('Collecte photo auteur'), info: $t(' Confirmez-vous que les photos auteurs ont été collectées ?') })"
                >
                  <v-icon color="primary">
                    {{ icons.mdiCheckboxMarkedCircleOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('Cliquez ici pour mettre à jour') }}</span>

            </v-tooltip>
          </div>
        </template>
        <template #[`item.word_file1`]="{item}">
          <div :style="stateColor(item.publication_colors, 'word_file')">
          <v-tooltip bottom >
              <template v-slot:activator="{ on, attrs }">
                <span
                  style="cursor: pointer"
                  v-bind="attrs"
                  @click="openDialog({...item, action: 'collecte_word_file',activity_date: item.word_file,date_column:'word_file', 'activity_date_label': $t('Collecte fichier word'), info: $t('Confirmez-vous que le fichier word a été collecté ?') })"
                  v-on="on"
                ><span v-if="item.word_file && !item.word_file.includes('0000')">{{ formatDate(item.word_file || null, { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span></span>
              </template>
              <span v-if="!item.publication_records || (item.publication_records  && item.publication_records.filter(el=>el.type === 'collecte_word_file' && el.comment).length === 0)">{{ $t('Cliquez ici pour mettre à jour') }}</span>
              <template v-else>
                <div>
                  <p
                    v-for="precord in item.publication_records.filter(el=>el.type === 'collecte_word_file' && el.comment)"

                    :key="precord.id"
                  >
                    <span class="mr-2">{{ precord.user?precord.user.code:'' }}</span>
                    <span class="mr-2" v-if="precord.date_record">{{ formatDate(precord.date_record || null, { month: 'numeric', day: 'numeric' }) }}</span>
                    <span class="mr-2">{{ precord.comment }}</span>
                  </p>
                </div>
              </template>
            </v-tooltip>
          <v-tooltip
            v-if="!item.word_file || item.word_file.includes('0000')"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn

                x-small
                color="primary"
                text
                v-bind="attrs"
                v-on="on"
                @click="openDialog({...item, action: 'collecte_word_file',activity_date: item.word_file,date_column:'word_file', 'activity_date_label': $t('Collecte fichier Word'), info: $t('Confirmez-vous que le fichier Word a été collecté ?') })"
              >
                <v-icon color="primary">
                  {{ icons.mdiCheckboxMarkedCircleOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('Cliquez ici pour mettre à jour') }}</span>

          </v-tooltip>
          </div>
        </template>
        <template #[`item.short_biography1`]="{item}">
          <div :style="stateColor(item.publication_colors, 'short_biography')">
            <template >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    style="cursor: pointer"
                    v-bind="attrs"
                    @click="openDialog({...item, action: 'collecte_short_biography',activity_date: item.short_biography,date_column:'short_biography', 'activity_date_label': $t('Collecte biographie courte'), info: $t('Confirmez-vous que la courte biographie a été collectée ?') })"
                    v-on="on"
                  ><span v-if="item.short_biography && !item.short_biography.includes('0000')">{{ formatDate(item.short_biography || null, { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span></span>
                </template>
                <span v-if="!item.publication_records  || (item.publication_records  && item.publication_records.filter(el=>el.type === 'collecte_short_biography' && el.comment).length === 0)">{{ $t('Cliquez ici pour mettre à jour') }}</span>
                <template v-else>
                  <div>
                    <p
                      v-for="precord in item.publication_records.filter(el=>el.type === 'collecte_short_biography' && el.comment)"

                      :key="precord.id"
                    >
                      <span class="mr-2">{{ precord.user?precord.user.code:'' }}</span>
                      <span class="mr-2" v-if="precord.date_record">{{ formatDate(precord.date_record || null, { month: 'numeric', day: 'numeric' }) }}</span>
                      <span class="mr-2">{{ precord.comment }}</span>
                    </p>
                  </div>
                </template>
              </v-tooltip>
            </template>
            <v-tooltip
              v-if="!item.short_biography || item.short_biography.includes('0000')"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn

                  x-small
                  color="primary"
                  text
                  v-bind="attrs"
                  v-on="on"
                  @click="openDialog({...item, action: 'collecte_short_biography',activity_date: item.short_biography,date_column:'short_biography', 'activity_date_label': $t('Collecte biographie courte'), info: $t('Confirmez-vous que la courte biographie a été collectée ?') })"
                >
                  <v-icon color="primary">
                    {{ icons.mdiCheckboxMarkedCircleOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('Cliquez ici pour mettre à jour') }}</span>

            </v-tooltip>
          </div>
        </template>
        <template #[`item.resume1`]="{item}">
          <div :style="stateColor(item.publication_colors, 'resume')">
            <template>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    style="cursor: pointer"
                    v-bind="attrs"
                    @click="openDialog({...item, action: 'collecte_resume',activity_date: item.resume,date_column:'resume', 'activity_date_label': $t('Collecte résumé'), info: $t('Confirmez-vous que le résumé a été collecté ?') })"
                    v-on="on"
                  ><span  v-if="item.resume && !item.resume.includes('0000')">{{ formatDate(item.resume || null, { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span></span>
                </template>
                <span v-if="!item.publication_records  || (item.publication_records  &&  item.publication_records.filter(el=>el.type === 'collecte_resume' && el.comment).length === 0)">{{ $t('Cliquez ici pour mettre à jour') }}</span>
                <template v-else>
                  <div>
                    <p
                      v-for="precord in item.publication_records.filter(el=>el.type === 'collecte_resume' && el.comment)"

                      :key="precord.id"
                    >
                      <span class="mr-2">{{ precord.user?precord.user.code:'' }}</span>
                      <span class="mr-2" v-if="precord.date_record">{{ formatDate(precord.date_record || null, { month: 'numeric', day: 'numeric' }) }}</span>
                      <span class="mr-2">{{ precord.comment }}</span>
                    </p>
                  </div>
                </template>
              </v-tooltip>
            </template>
            <v-tooltip
              v-if="!item.resume || item.resume.includes('0000')"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn

                  x-small
                  color="primary"
                  text
                  v-bind="attrs"
                  v-on="on"
                  @click="openDialog({...item, action: 'collecte_resume',activity_date: item.resume,date_column:'resume', 'activity_date_label': $t('Collecte résumé'), info: $t('Confirmez-vous que le résumé a été collecté ?') })"
                >
                  <v-icon color="primary">
                    {{ icons.mdiCheckboxMarkedCircleOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('Cliquez ici pour mettre à jour') }}</span>

            </v-tooltip>
          </div>
        </template>

        <template #[`item.visual_idea1`]="{item}">
          <div :style="stateColor(item.publication_colors, 'visual_idea')">
            <template >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    style="cursor: pointer"
                    v-bind="attrs"
                    @click="openDialog({...item, action: 'collecte_visual_idea',activity_date: item.visual_idea,date_column:'visual_idea', 'activity_date_label': $t('Collecte l\'idée visuele'), info: $t('Confirmez-vous que le visuel-idée a été collecté ?') })"
                    v-on="on"
                  ><span v-if="item.visual_idea && !item.visual_idea.includes('0000')">{{ formatDate(item.visual_idea || null, { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span></span>
                </template>
                <span v-if="!item.publication_records  || (item.publication_records  && item.publication_records.filter(el=>el.type === 'collecte_visual_idea' && el.comment).length === 0)">{{ $t('Cliquez ici pour mettre à jour') }}</span>
                <template v-else>
                  <div>
                    <p
                      v-for="precord in item.publication_records.filter(el=>el.type === 'collecte_visual_idea' && el.comment)"

                      :key="precord.id"
                    >
                      <span class="mr-2">{{ precord.user?precord.user.code:'' }}</span>
                      <span class="mr-2" v-if="precord.date_record">{{ formatDate(precord.date_record || null, { month: 'numeric', day: 'numeric' }) }}</span>
                      <span class="mr-2">{{ precord.comment }}</span>
                    </p>
                  </div>
                </template>
              </v-tooltip>
            </template>
            <v-tooltip
              v-if="!item.visual_idea || item.visual_idea.includes('0000')"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn

                  x-small
                  color="primary"
                  text
                  v-bind="attrs"
                  v-on="on"
                  @click="openDialog({...item, action: 'collecte_visual_idea',activity_date: item.visual_idea,date_column:'visual_idea', 'activity_date_label': $t('Collecte le visuele-idée'), info: $t('Confirmez-vous que le visuel-idée a été collecté ?') })"
                >
                  <v-icon color="primary">
                    {{ icons.mdiCheckboxMarkedCircleOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('Cliquez ici pour mettre à jour') }}</span>

            </v-tooltip>
          </div>
        </template>
        <template #[`item.address_phone1`]="{item}">
          <div :style="stateColor(item.publication_colors, 'address_phone')">
          <template >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  style="cursor: pointer"
                  v-bind="attrs"
                  @click="openDialog({...item, action: 'collecte_address_phone',activity_date: item.address_phone,date_column:'address_phone', 'activity_date_label': $t('Collecte l\'adresse et téléphone'), info: $t('Confirmez-vous que l\'adresse et le téléphone ont été collectés ?') })"
                  v-on="on"
                ><span v-if="item.address_phone != null && !item.address_phone.includes('0000')">{{ formatDate(item.address_phone || null, { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span></span>
              </template>
              <span v-if="!item.publication_records  || (item.publication_records  &&  item.publication_records.filter(el=>el.type === 'collecte_address_phone' && el.comment).length === 0)">{{ $t('Cliquez ici pour mettre à jour') }}</span>
              <template v-else>
                <div>
                  <p
                    v-for="precord in item.publication_records.filter(el=>el.type === 'collecte_address_phone' && el.comment)"

                    :key="precord.id"
                  >
                    <span class="mr-2">{{ precord.user?precord.user.code:'' }}</span>
                    <span class="mr-2" v-if="precord.date_record">{{ formatDate(precord.date_record || null, { month: 'numeric', day: 'numeric' }) }}</span>
                    <span class="mr-2">{{ precord.comment }}</span>
                  </p>
                </div>
              </template>
            </v-tooltip>
          </template>
          <v-tooltip
            v-if="!item.address_phone || item.address_phone.includes('0000')"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn

                x-small
                color="primary"
                text
                v-bind="attrs"
                v-on="on"
                @click="openDialog({...item, action: 'collecte_address_phone',activity_date: item.address_phone,date_column:'address_phone', 'activity_date_label': $t('Collecte l\'adresse et téléphone'), info: $t('Confirmez-vous que l\'adresse et le téléphone ont été collectés ?') })"
              >
                <v-icon color="primary">
                  {{ icons.mdiCheckboxMarkedCircleOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('Cliquez ici pour mettre à jour') }}</span>

          </v-tooltip>
        </div>
        </template>
        <template #[`item.best_sentence1`]="{item}">
          <div :style="stateColor(item.publication_colors, 'best_sentence')">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    style="cursor: pointer"
                    v-bind="attrs"
                    @click="openDialog({...item, action: 'collecte_best_sentence',activity_date: item.best_sentence,date_column:'best_sentence', 'activity_date_label': $t('Collecte meilleure phrase'), info: $t('Confirmez-vous que la meilleure phrase a été recueillie ?') })"
                    v-on="on"
                  ><span v-if="item.best_sentence && !item.best_sentence.includes('0000')">{{ formatDate(item.best_sentence || null, { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span></span>
                </template>
                <span v-if="!item.publication_records  || (item.publication_records  && item.publication_records.filter(el=>el.type === 'collecte_best_sentence' && el.comment).length === 0)">{{ $t('Cliquez ici pour mettre à jour') }}</span>
                <template v-else>
                  <div>
                    <p
                      v-for="precord in item.publication_records.filter(el=>el.type === 'collecte_best_sentence' && el.comment)"

                      :key="precord.id"
                    >
                      <span class="mr-2">{{ precord.user?precord.user.code:'' }}</span>
                      <span class="mr-2" v-if="precord.date_record">{{ formatDate(precord.date_record || null, { month: 'numeric', day: 'numeric' }) }}</span>
                      <span class="mr-2">{{ precord.comment }}</span>
                    </p>
                  </div>
                </template>
              </v-tooltip>
            <v-tooltip
              v-if="!item.best_sentence || item.best_sentence.includes('0000')"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn

                  x-small
                  color="primary"
                  text
                  v-bind="attrs"
                  v-on="on"
                  @click="openDialog({...item, action: 'collecte_best_sentence',activity_date: item.best_sentence,date_column:'best_sentence', 'activity_date_label': $t('Collecte meilleure phrase'), info: $t('Confirmez-vous que la meilleure phrase a été recueillie ?') })"
                >
                  <v-icon color="primary">
                    {{ icons.mdiCheckboxMarkedCircleOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('Cliquez ici pour mettre à jour') }}</span>

            </v-tooltip>
          </div>
        </template>
        <template #[`item.errors_before_correction1`]="{item}">
          <div  :style="stateColor(item.publication_colors, 'errors_before_correction')">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  style="cursor: pointer"
                  v-bind="attrs"
                  @click="openDialog({...item, column_type: 'text', action: 'collecte_errors_before_correction',activity_date: item.errors_before_correction,date_column:'errors_before_correction', 'activity_date_label': $t('Nb de fautes avant correction'), info: $t('Veuillez indiquer le nombre d\'erreurs') })"
                  v-on="on"
                >
                  <span
                    v-if="item.errors_before_correction"
                  >
               {{ item.errors_before_correction }}
             </span>
                 <v-icon
                   v-else
                   color="primary"
                 >
                 {{ icons.mdiCheckboxMarkedCircleOutline }}
               </v-icon>
                </span>

              </template>
              <span v-if="!item.publication_records || (item.publication_records && item.publication_records.filter(el=>el.type === 'collecte_errors_before_correction' && el.comment).length === 0)">{{ $t('Cliquez ici pour mettre à jour') }}</span>
              <template v-else>
                <div>
                  <p
                    v-for="precord in item.publication_records.filter(el=>el.type === 'collecte_errors_before_correction' && el.comment)"

                    :key="precord.id"
                  >
                    <span class="mr-2">{{ precord.user?precord.user.code:'' }}</span>
                    <span
                      v-if="precord.date_record"
                      class="mr-2"
                    >{{ formatDate(precord.date_record || null, { month: 'numeric', day: 'numeric' }) }}</span>
                    <span class="mr-2">{{ precord.comment }}</span>
                  </p>
                </div>
              </template>
            </v-tooltip>
          </div>
        </template>
        <template #[`item.file_blocked1`]="{item}">
          <div :style="stateColor(item.publication_colors, 'file_blocked')">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  style="cursor: pointer"
                  v-bind="attrs"
                  @click="openDialog({...item, column_type: 'select', items: blockeds.filter(ele => ele.id), selectId:'id', selectLabel: 'label', action: 'collecte_file_blocked',activity_date: item.file_blocked,date_column:'file_blocked', 'activity_date_label': $t('Statut dossier'), info: $t('Veuillez entrer les informations ci-dessous') })"
                  v-on="on"
                >
                  <span
                    v-if="item.file_blocked"
                  >
               {{ item.file_blocked }}
             </span>
                 <v-icon
                   v-else
                   color="primary"
                 >
                  {{ icons.mdiCheckboxMarkedCircleOutline }}
               </v-icon>
                </span>

              </template>
              <span v-if="!item.publication_records ||  (item.publication_records.filter(el=>el.comment && el.type === 'collecte_file_blocked').length === 0)">{{ $t('Cliquez ici pour mettre à jour') }}</span>
              <template v-else>
                <div>
                  <p
                    v-for="precord in item.publication_records.filter(el=>el.type === 'collecte_file_blocked' && el.comment)"

                    :key="precord.id"
                  >
                    <span class="mr-2">{{ precord.user?precord.user.code:'' }}</span>
                    <span
                      v-if="precord.date_record"
                      class="mr-2"
                    >{{ formatDate(precord.date_record || null, { month: 'numeric', day: 'numeric' }) }}</span>
                    <span class="mr-2">{{ precord.comment }}</span>
                  </p>
                </div>
              </template>
            </v-tooltip>
          </div>
        </template>

        <template #[`item.quick_file1`]="{item}">
          <div class="text-center"  :style="stateColor(item.publication_colors, 'quick_file')">
            <v-icon
              v-if="item.quick_file"
              small
              color="success"
            >
              {{ icons.mdiCheckCircle }}
            </v-icon>
          </div>
        </template>

        <template #[`item.bnf_form1`]="{item}">
              <div
                v-if="isPublicationbod(item)"
                :style="stateColor(item.publication_colors, 'bnf_form')"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      style="cursor: pointer"
                      v-bind="attrs"
                      @click="openDialog({...item, action: 'paiement_bnf_form',activity_date: item.bnf_form,date_column:'bnf_form', 'activity_date_label': $t('BNF formulaire'), info: $t('Confirmez-vous que le formulaire BNF a été fait ?') })"
                      v-on="on"
                    ><span v-if="item.bnf_form && !item.bnf_form.includes('0000')">{{ formatDate(item.bnf_form || null, { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span></span>
                  </template>
                  <span v-if="!item.publication_records || (item.publication_records.filter(el=>el.comment && el.type === 'paiement_bnf_form').length === 0)">{{ $t('Cliquez ici pour mettre à jour') }}</span>
                  <template v-else>
                    <div>
                      <p
                        v-for="precord in item.publication_records.filter(el=>el.type === 'paiement_bnf_form')"
                        :key="precord.id"
                      >
                        <span class="mr-2">{{ precord.user?precord.user.code:'' }}</span>
                        <span
                          v-if="precord.date_record"
                          class="mr-2"
                        >{{ formatDate(precord.date_record || null, { month: 'numeric', day: 'numeric' }) }}</span>
                        <span class="mr-2">{{ precord.comment }}</span>
                      </p>
                    </div>
                  </template>
                </v-tooltip>
                <v-tooltip
                  v-if="!item.bnf_form || item.bnf_form.includes('0000')"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      color="primary"
                      text
                      v-bind="attrs"
                      v-on="on"
                      @click="openDialog({...item, action: 'paiement_bnf_form', activity_date: item.bnf_form,date_column:'bnf_form', 'activity_date_label': $t('BNF formulaire'), info: $t('Confirmez-vous que le formulaire BNF a été fait ?') })"
                    >
                      <v-icon color="primary">
                        {{ icons.mdiCheckboxMarkedCircleOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('Cliquez ici pour mettre à jour') }}</span>
                </v-tooltip>
              </div>
            </template>

        <template #[`item.brame_update1`]="{item}">
              <div :style="stateColor(item.publication_colors, 'brame_update')">
                <template>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                  <span
                    style="cursor: pointer"
                    v-bind="attrs"
                    @click="openDialog({...item, action: 'publication_brame_update',activity_date: item.brame_update,date_column:'brame_update', 'activity_date_label': $t('Mise à jour BRAME'), info: $t('Confirmez-vous que la mise à jour BRAME a été faite ?') })"
                    v-on="on"
                  ><span v-if="item.brame_update && !item.brame_update.includes('0000')">{{ formatDate(item.brame_update || null, { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span></span>
                    </template>
                    <span v-if="!item.publication_records ||  (item.publication_records.filter(el=>el.comment && el.type === 'publication_brame_update').length === 0)">{{ $t('Cliquez ici pour mettre à jour') }}</span>
                    <template v-else>
                      <div>
                        <p
                          v-for="precord in item.publication_records.filter(el=>el.type === 'publication_brame_update')"

                          :key="precord.id"
                        >
                          <span class="mr-2">{{ precord.user?precord.user.code:'' }}</span>
                          <span
                            v-if="precord.date_record"
                            class="mr-2"
                          >{{ formatDate(precord.date_record || null, { month: 'numeric', day: 'numeric' }) }}</span>
                          <span class="mr-2">{{ precord.comment }}</span>
                        </p>
                      </div>
                    </template>
                  </v-tooltip>
                </template>
                <v-tooltip
                  v-if="item.publication_primento && (!item.brame_update || item.brame_update.includes('0000'))"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      color="primary"
                      text
                      v-bind="attrs"
                      v-on="on"
                      @click="openDialog({...item, action: 'publication_brame_update',activity_date: item.brame_update,date_column:'brame_update', 'activity_date_label': $t('Mise à jour BRAME'), info: $t('Confirmez-vous que la mise à jour BRAME a été faite ?') })"
                    >
                      <v-icon color="primary">
                        {{ icons.mdiCheckboxMarkedCircleOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('Cliquez ici pour mettre à jour') }}</span>
                </v-tooltip>
              </div>
            </template>
        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>

              <v-list-item
                v-if="$can('edit','Author')"
                :to="{name:'author-info',params:{id:item.author_id}}"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentEditOutline }}
                  </v-icon>
                  <span>{{ $t('Ajouter l\'info de l\'auteur') }}</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                :to="{name:'author-details',params:{id:item.author_id}}"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiChartBoxOutline }}
                  </v-icon>
                  <span>{{ $t('Fiche Synoptique') }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
<!--          <v-btn
            v-if="item.word_file != null && (!item.tasks || item.tasks.length === 0) && $can('edit', 'Cautation')"
            color="primary"
            small
            @click="openDialogAttribution({...item, publication_id: item.id, gestion_id:null, assistant_id: null, task_date: null })"
            icon
          >
            <v-icon class="me-2" color="primary">{{ icons.mdiCalendar }}</v-icon>
          </v-btn>-->
        </template>
          </Row>
        </template>
      </v-data-table>
      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <rows-per-page :options="options" />
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="page"
              total-visible="6"
              :length="pageCount"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="deleteDialog"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 300 : '100%'"
    >
      <v-card>
        <v-card-title>{{ $t("Confirmer la suppression") }}</v-card-title>
        <v-divider />
        <v-card-text>{{ $t("Confirmez-vous de vouloir supprimer") }}</v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            small
            outlined
            @click="deleteDialog = false"
          >
            {{ $t("Non") }}
          </v-btn>
          <v-spacer />
          <v-btn
            small
            color="primary"
            :loading="loading"
            @click="deleteItem"
          >
            {{ $t("Oui") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"
      :light="$vuetify.theme.dark"
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiClose,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
  mdiPencilOutline,
  mdiCheckCircle,
  mdiCalendar,
  mdiCheckboxMarkedCircleOutline,
  mdiChartBoxOutline,
  mdiFileDocumentEditOutline,
} from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'

// sidebar
import { avatarText, formatDate } from '@core/utils/filter'
import store from '@/store'
import itemStoreModule from './itemStoreModule'
import controller from './Controller'
import RowsPerPage from '@/components/RowsPerPage'
import CollectionForm from '@/views/apps/publications/SignatureForm'
import AttributionForm from '@/views/apps/publications/AttributionForm'
import HeaderFilters from '@/components/HeaderFilters'
import ability from '@/plugins/acl/ability'
import TableRow from '@/components/TableRow'
import Row from "@/components/Row";
import axios from "@axios";
import AuthorWidget from "@/views/apps/author/author-view/components/AuthorWidget.vue";

export default {
  components: {
    AuthorWidget,
    Row,
    TableRow,
    HeaderFilters,
    AttributionForm,
    CollectionForm,
    RowsPerPage,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-publication'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, itemStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      item,
      items,
      collectTableColumns: tableColumns,
      filters,
      publicationStates,
      searchQuery,
      payFilter,
      sourceFilter,
      statusFilter,
      stateFilter,
      tabFilter,
      totalItems,
      loading,
      options,
      blockeds,
      publicationTotalLocal,
      selectedRows,
      isFormActive,
      isFormAttributionActive,
      snackBarMessage,
      isSnackbarBottomVisible,
      isPublicationbod,
      openDialog,
      openDialogAttribution,
      deleteItem,
      deleteDialog,
      confirmDelete,
      fetchItems,tableHeight,
      resolvePublicationRoleVariant,
      resolvePublicationRoleIcon,
      resolvePublicationStatusVariant,
      resolvePublicationStatusName,
      resolvePublicationTotalIcon,
    } = controller()

    const superiors = ref([])
    const assistants = ref([])

    tabFilter.value = 'Collect'

    const payOptions = [
      { title: 'Admin', value: 'admin' },
      { title: 'Publication', value: 'publication' },
      { title: 'Editor', value: 'editor' },
      { title: 'Maintainer', value: 'maintainer' },
      { title: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { title: 'Basic', value: 'basic' },
      { title: 'Company', value: 'company' },
      { title: 'Enterprise', value: 'enterprise' },
      { title: 'Standard', value: 'standard' },
    ]

    const statusOptions = [
      { title: 'Actif', value: 1 },
      { title: 'Inactif', value: 0 },
    ]
    store
      .dispatch('app-publication/fetchCountries', { per_page: 500 })
    store
      .dispatch('app-publication/fetchSources', { per_page: 500 })
    store
      .dispatch('app/fetchGenres', { per_page: 500 })
    store
      .dispatch('app/fetchFormats', { per_page: 500 })
    store
      .dispatch('app-publication/fetchStatuses', { per_page: 500, status_type: 'publication' })
    stateFilter.value = [publicationStates.value.SIGNED_STATE, publicationStates.value.COLLECTED_STATE,
      publicationStates.value.TEXT_STATE,
      publicationStates.value.COVER_STATE,
      publicationStates.value.SUPERVISOR_STATE,
      publicationStates.value.PUBLICATION_STATE,
      /*publicationStates.value.PAYMENT_STATE,
      publicationStates.value.RESILIATION_STATE,*/
    ]

    axios
      .get('/listWorkers', {
        params: {
          term: '', type: 'text_cover', user: JSON.parse(localStorage.getItem('userData')).id || null, rowsPerPage: 100,
        },
      })
      .then(response => {
        for (let i = 0; i < response.data.length; i++) {
          const u = response.data[i]
          const indexU = assistants.value.findIndex(el => el.id === u.id)
          if (indexU < 0) {
            assistants.value.push(u)
          }
        }
        const indexH = filters.value.findIndex(ele => ele.value === 'assistant.code')
        if (indexH > 0) {
          filters.value[indexH].filter.values = assistants.value
        }
      })

    axios
      .get('/publications/text/settings', {
        params: {
        },
      })
      .then(response => {
        if (response.data && response.data.errors_before_corrections) {
          const indexH = filters.value.findIndex(ele => ele.value === 'errors_before_correction')
          if (indexH > 0) {
            const errors = filters.value[indexH].filters[0].values = response.data.errors_before_corrections.map(ele => ele.errors_before_correction)

            filters.value[indexH].filters[0].min = errors[0]
            filters.value[indexH].filters[0].max = errors[errors.length - 1]

            // catch for out of range error
            //filters.value[indexH].filters[0].value = [errors[0], errors[errors.length - 1]]
          }
        }
        if (response.data && response.data.error_per_pages) {
          const indexH1 = filters.value.findIndex(ele => ele.value === 'error_per_page')
          if (indexH1 > 0) {
            const errors = filters.value[indexH1].filters[0].values = response.data.error_per_pages.map(ele => ele.error_per_page)

            filters.value[indexH1].filters[0].min = errors[0]
            filters.value[indexH1].filters[0].max = errors[errors.length - 1]

            // catch for out of range error
            //filters.value[indexH1].filters[0].value = [errors[0], errors[errors.length - 1]]

            /* filters.value[indexH1].filters[0].values = response.data.error_per_pages.map((ele) => {
              return {
                id: ele.error_per_page, label: ele.error_per_page,
              }}) */
          }
        }
      })

    axios
      .post('/particular_case/list', { page: 1000 })
      .then(response => {
        //store.state['app-manuscript/countries'] = response.data.data
        const indexH = filters.value.findIndex(ele => ele.value === 'manuscript.particular_case.label')
        if (indexH > 0) {
          filters.value[indexH].filter.values = response.data
          filters.value[indexH].fields[0].items = response.data
        }
      }).catch(error => console.log(error))

    filters.value = tableColumns.filter(ele => !ele.value || ability.can('view_' + ele.value, 'Collect'))

    return {
      item,
      items,
      superiors,
      tableColumns,
      filters,
      searchQuery,
      tabFilter,
      payFilter,
      sourceFilter,
      statusFilter,
      stateFilter,
      totalItems,
      payOptions,
      planOptions,
      statusOptions,
      loading,
      options,
      publicationTotalLocal,
      isFormActive,
      selectedRows,
      snackBarMessage,
      blockeds,
      isFormAttributionActive ,
      isSnackbarBottomVisible,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      isPublicationbod,
      openDialog,
      openDialogAttribution,
      deleteItem,
      deleteDialog,
      confirmDelete,
      avatarText,
      formatDate,
      resolvePublicationRoleVariant,
      resolvePublicationRoleIcon,
      resolvePublicationStatusVariant,
      resolvePublicationStatusName,
      resolvePublicationTotalIcon,
      fetchItems,tableHeight,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiClose,
        mdiExportVariant,
        mdiAccountOutline,
        mdiPencilOutline,
        mdiCheckCircle,
        mdiCalendar,
        mdiCheckboxMarkedCircleOutline,
        mdiChartBoxOutline,
        mdiFileDocumentEditOutline,
      },
    }
  },
}
</script>
<style lang="scss">
  /*table {

    background-color: white;
    margin: auto;
    border-collapse: separate;
    border-spacing: 0;
    display: block;
    overflow-x: scroll;
  }
  .v-data-table td, th {
    margin: 0;
    border: 1px solid grey;
    white-space: nowrap;
    border-top-width: 0px;
  }

  .v-data-table thead tr:first-child
  }
  .fixed-column {
    position: absolute;
    width: 5em;
    left: 0;
    top: auto;
    border-top-width: 1px;
    margin-top: -1px;
  }*/
</style>
<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
